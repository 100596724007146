<template>
  <div class="status-cell">
    <BorrowReturnStatusCard
      class="w-100 text-center p-0"
      :status="params.value.name"
    />
  </div>
</template>

<script>
import BorrowReturnStatusCard from "../../../BorrowReturn/BorrowReturnStatusCard.vue";
export default {
  name: "BorrowReturnStatusCardCell",
  components: {
    BorrowReturnStatusCard,
  },
};
</script>

<style scoped>
.status-cell {
  min-width: 160px;
  height: 100%;
  margin: 2px auto;
}
</style>
