<template>
  <div class="d-flex">
    <p class="me-1">{{ params.value.name }}</p>
    <p
      class="text-primary"
      v-tooltip="
        `เบอร์โทร ${params.value.phone}
        อีเมล ${params.value.email}`
      "
    >
      (i)
    </p>
  </div>
</template>

<script>
// style of tooltips is in App.js
import Tooltip from "primevue/tooltip";

export default {
  directives: {
    tooltip: Tooltip,
  },
};
</script>
