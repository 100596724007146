<template>
  <img
    v-show="params.show"
    class="cursor-pointer"
    src="../../../../assets/edit_pencil.svg"
    alt=""
    @click="onIconClick"
  />
</template>

<script>
export default {
  emits: ["icon-click"],
  methods: {
    onIconClick() {
      this.params.onEditIconClick(this.params.value);
    },
  },
};
</script>
