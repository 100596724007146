<template>
  <div class="dropdown">
    <div class="dropdown-content">
      <p
        class="option cursor-pointer py-2 m-0"
        :class="{
          'border-top': option.value === 'CANCEL' && status.options.length > 1,
          'border-bottom':
            option.value === 'ย้อนกลับ' && status.options.length > 1,
        }"
        v-for="option in status.options"
        :key="option.value"
        @click="onStatusClick(option)"
      >
        {{ option.label }}
      </p>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  directives: {
    ClickOutside,
  },
  data() {
    return {
      status: {
        name: "",
        options: [],
      },
      selectedOption: "",
      allowedOptions: [],
    };
  },
  computed: {},
  methods: {
    getValue() {
      return this.params.value;
    },
    onStatusClick(selectedOption) {
      this.params.onStatusChange({
        oldStatus: this.status.name,
        newStatus: selectedOption.value,
        borrow_asset_id: this.params.data.borrow_asset_id,
      });
      this.params.stopEditing();
    },
  },
  created() {
    const { name, options } = this.params.value;
    this.status = {
      name,
      options,
    };
  },
};
</script>

<style lang="scss" scoped>
.status-card {
  padding: 0 10px;
  border: 1px solid #e0e2e7;
  border-radius: 8px;
  p {
    margin: 0;
  }
}

// .dropdown {
//   position: relative;
//   z-index: 1000;
//   display: inline-block;
//   min-width: 160px;
// }

.menu-dropdown {
  background-image: url("../../../../assets/down-arrow-black.svg");
  background-repeat: no-repeat;
  background-position: 97% 50%;
  padding: 0 40px 0 10px;
  border: 1px solid #e0e2e7;
  border-radius: 8px;
}

.dropdown-content {
  min-width: 190px;
  .option {
    line-height: normal;
    &:hover {
      color: #007afe;
    }
  }
}
</style>
