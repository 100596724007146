<template>
  <img
    class="cursor-pointer"
    src="../../../../assets/BorrowReturn/detail.svg"
    alt=""
    @click="onIconClick"
  />
</template>

<script>
export default {
  emits: ["icon-click"],
  methods: {
    onIconClick() {
      this.params.onDetailIconClick(this.params.value);
    },
  },
};
</script>
